import { Divider, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import { ReactComponent as WarningIcon } from 'common/Styles/svg_icons/Icons_Filled_exclamation-triangle_24px.svg';
import { ReactComponent as ChevronDown } from 'common/Styles/svg_icons/Icons_Filled_chevron-down_24px.svg';
import { ReactComponent as ChevronRight } from 'common/Styles/svg_icons/Icons_Filled_chevron-right_24px.svg';
import { ReactComponent as EditIcon } from "common/Styles/svg_icons/Icons_Filled_pen_24px.svg";
import {
  DataGrid,
  GridCellCheckboxRenderer,
  GridColDef,
  GridColumnHeaderParams,
  GridHeaderCheckbox,
  GridRenderCellParams,
  GridRowParams,
  GridSelectionModel,
  GridValueGetterParams
} from '@mui/x-data-grid';
import React, { 
  useEffect,
  useState 
} from 'react';
import {
  AppRowDTO,
  MgmtMap,
  ReportPackageDTO,
  SponsorAppReport,
  SponsorAppRowModel,
  SponsorCard
} from '../../../common/Types/ReportMgmtTypes';
import { useAppDispatch } from '../../../state/hooks';
import {
  fetchSponsorApps,
  setSelectedAppRows,
  saveElections,
  electReportPackageByApp,
  electAllByReport
} from "../reportManagementSlice";
import styles from './reportsCard.module.scss';

export interface SponsorCardProps {
  card: SponsorCard;
}

const defaultColor = "#0EB1BD";
const disabledColor = "#8A9092";
const activeRowColor = "#E3FFFF";
const hoverRowColor = "#E3FFFF";

type EditState = 'idle' | 'unedited' | 'edited' | 'empty';

export const ReportsCard = ({card}: SponsorCardProps) => {

  const returnUniqueReportPackages = (apps: MgmtMap<SponsorAppRowModel>): MgmtMap<SponsorAppReport> => { 
    const map: MgmtMap<SponsorAppReport> = {};
    
    Object.values(apps).forEach((app) => {
      Object.values(app.reports).forEach((report) => {
        map[report.id] = report;
      })
    })
    return map;
  }
  
  const dispatch = useAppDispatch();
  const {cardSponsor, cardApps, selectedAppRows} = card;
  const [expanded, setExpanded] = useState<boolean>(false);
  const [editState, setEditState] = useState<EditState>('idle');
  const [editControlText, setEditControlText] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [flaggedRows, setFlaggedRows] = useState<string[]>();
  const colDefaultWidth = 360;
  const uniqueReportPackages: MgmtMap<SponsorAppReport> = returnUniqueReportPackages(cardApps);

  const activeGrid = editState === 'edited' || editState === 'unedited';

  useEffect(() => {
    if (validateElections()) {
      setErrorMessage('');
    }
  }, [cardApps, selectedAppRows])

  const electionStatusClass = (rowId: string): string => {
    return flaggedRows?.some((row) => row === rowId) ? styles.flagged : styles.unflagged;
  }

  const validateElections = (): boolean => {
    const incompleteRows = selectedAppRows.filter((row) => !Object.values(cardApps[row].reports).some((report) => report.isActive));
    setFlaggedRows(incompleteRows);
    return !incompleteRows.length;
  }

  const handleEdit = () => {
    setEditState('edited');
    setEditControlText('save');
  }

  const handleReportPackageSelection = (election: boolean, reportPackageId: string, sponsorAppId: string) => {
    const dto: ReportPackageDTO = {
      sponsorAppId,
      reportPackageId,
      election,
      sponsorId: cardSponsor.id
    };
    dispatch(electReportPackageByApp(dto));
    handleEdit();
  }

  const allColScan = (reportId: string): boolean => {
    if (!selectedAppRows.length) return false;
    let result: boolean = true;

    selectedAppRows.forEach((selectedRow) => {
      const target = cardApps[selectedRow].reports[reportId].isActive;
      if(!target) {
        result = false;
      }
    })
    return result;
  }

  const handleElectAllCol = (reportId: string, election: boolean) => {
    const dto = {
      reportId,
      sponsorId: cardSponsor.id,
      election
    };
    dispatch(electAllByReport(dto));
  }

  const sponsorAppReportColumnSet = (): GridColDef[] => {
    const map: MgmtMap<GridColDef> = {};
    
    Object.values(uniqueReportPackages).forEach((report) => {
      const def: GridColDef = {
        field: report.name,
        headerName: report.displayName,
        width: colDefaultWidth,
        align: "center",
        headerAlign: "center",
        filterable: false,
        sortable: false,
        disableColumnMenu: true,
        disableReorder: true,
        valueGetter: (params: GridValueGetterParams) => {
          return params.row.reports[report.id];
        },
        renderHeader: (params: GridColumnHeaderParams) => {
          const isChecked = allColScan(report.id);

          return (
            <Box
              sx={{
                flexDirection: "column",
                alignItems: "center",
                display: "flex",
                justifyContent: "flex-end"
              }}
            >
              <Typography
                sx={{
                  position: "relative",
                  height: "45px",
                  textAlign: "center",
                  whiteSpace: "normal",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  maxWidth: "140px",
                  fontSize: "16px",
                  fontWeight: "500"
                }}
              >
                {params.colDef.headerName}
              </Typography>
              <Checkbox
                checked={isChecked}
                disabled={!(activeGrid && !!selectedAppRows)}
                onClick={() => handleElectAllCol(report.id, !isChecked)}
              />
            </Box>
          );
        },
        renderCell: (params: GridRenderCellParams<SponsorAppReport>) => {
          const rowActive = params.api.isRowSelected(params.id);
          return (
            <Checkbox
              checked={params.value.isActive}
              disabled={!(rowActive && activeGrid)}
              onClick={() =>
                handleReportPackageSelection(
                  !params.value.isActive,
                  params.value.id,
                  params.row.id
                )
              }
            />
          );
        },
      };
      map[report.id] = def;
    });

    const result = Object.values(map);

    return result;
  }

  const columns: GridColDef[] = [
    {
      field: "__check__",
      type: "checkboxSelection",
      width: 105,
      align: "center",
      resizable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      valueGetter: (params: GridValueGetterParams) => {
        return selectedAppRows.includes(params.id as string);
      },
      renderHeader: (params: GridColumnHeaderParams) => (
        <Box
          sx={{
            display: activeGrid ? "flex" : "none",
            flexDirection: "column",
            marginLeft: "6px"
          }}
        >
          <Typography sx={{
            position: "relative",
            top: "13px",
            fontSize: "16px",
            fontWeight: 500
          }}>
            Enable All
          </Typography>
          <GridHeaderCheckbox {...params} />
        </Box>
      ),
      renderCell: (params) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            position: "relative",
            alignItems: "center",
          }}
        >
          <WarningIcon
            className={electionStatusClass(params.row.id)}
            title={"You must select at least one report package for the enabled study."}
          />
          <GridCellCheckboxRenderer {...params} />
        </Box>
      ),
    },
    {
      field: "name",
      headerName: "Study Name",
      filterable: true,
      sortable: true,
      width: colDefaultWidth
    },
    ...sponsorAppReportColumnSet(),
  ];

  const toggleContent = () => {
    if (Object.keys(cardApps).length || expanded) {
      setExpanded(!expanded);
    } else {
      dispatch(fetchSponsorApps(cardSponsor))
      .catch((err) => {
        setEditState('empty');
      })
      .then(() => {
        setExpanded(true);
        setEditState('idle');
      });
    }
  }

  const handleRowSelection = (selectedRowIdList: GridSelectionModel) => {
    const dto: AppRowDTO = {
      sponsorId: cardSponsor.id,
      rows: selectedRowIdList as string[]
    };
    dispatch(setSelectedAppRows(dto));
    handleEdit();
  }

  const handleEditControlClick = () => {
    switch (editState) {
      case ('unedited'):
        setEditControlText('');
        setEditState('idle');
        setErrorMessage('');
        break;
      case ('edited'):
        //prevent saving if a user has activated a study but selected no report packages.
        if (!validateElections()) {
          setErrorMessage('You must select at least one report package for the enabled studies.');
          break;
        };
        dispatch(saveElections(cardSponsor.id));
        setEditControlText('');
        setEditState('idle');
        setErrorMessage('');
        break;
      case ('idle'):
        setEditControlText('cancel');
        setEditState('edited');
        setErrorMessage('')
        break;
      default:
        setEditState('empty');
        break;
    }
  }

  const rowClass = (rowId: string): string => {
    return flaggedRows?.some((row) => row === rowId) ? 'row-theme--incomplete' : 'row-theme--idle';
  }

  const renderAppReports = () => {
    return (
      <DataGrid
        sx={{
          border: 0,
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: activeRowColor,
          },
          "& .MuiDataGrid-row.Mui-selected:hover": {
            backgroundColor: hoverRowColor,
          },
          "& .MuiCheckbox-root.Mui-checked": {
            color: defaultColor,
          },
          "& .MuiCheckbox-root.Mui-disabled": {
            color: disabledColor
          },
          "& .MuiDataGrid-row": {
            border: 0,
          },
          "& .MuiDataGrid-columnHeaders": {
            border: 0
          },
          "& .MuiDataGrid-row.Mui-selected.row-theme--incomplete": {
            bgcolor: "rgba(255,219,85,0.1)",
          },
          "& .MuiDataGrid-columnHeader .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--sideRight":
          {
            display: "none",
          },
          "& .MuiDataGrid-cell": {
            border: 0,
          },
          "& .MuiDataGrid-columnHeaderTitleContainer": {
            padding: 'unset'
          },
          "& .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-iconButtonContainer":
          {
            position: "absolute",
            right: "1.5vw",
            visibility: "visible",
            width: "unset",
          },
          "& .MuiDataGrid-columnHeaders .MuiDataGrid-menuIcon": {
            visibility: "visible",
            width: "unset",
          },
          "& .MuiDataGrid-columnHeadersInner": {
            alignItems: "flex-end"
          },
          "& .MuiIconButton-sizeSmall .MuiSvgIcon-fontSizeSmall.MuiDataGrid-sortIcon":
          {
            transition: "none",
            opacity: "unset",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontSize: "16px"
          },
          "& .MuiDataGrid-cellContent": {
            fontSize: "16px",
          }
        }}
        rows={Object.values(cardApps)}
        pageSize={15}
        rowsPerPageOptions={[15]}
        columns={columns}
        autoHeight={true}
        checkboxSelection
        headerHeight={85}
        selectionModel={selectedAppRows}
        onSelectionModelChange={(selectedRowIdList) =>
          handleRowSelection(selectedRowIdList)
        }
        disableSelectionOnClick
        getRowClassName={(params: GridRowParams) =>
          `all-rows ${rowClass(params.row.id)}`
        }
        isRowSelectable={(params: GridRowParams) => activeGrid}
      />
    );
  }

  const dynamicChevron = (): JSX.Element => {
    return expanded ? <ChevronDown className={styles.chevron} title={'collapse'} /> : <ChevronRight className={styles.chevron} title={'expand'} />
  }

  const editIcon = (): JSX.Element | undefined => {
    if (editState === 'idle') {
      return <EditIcon style={{color: "#999D9F"}} />;
    }
  }

  return (
    <Card key={`${cardSponsor.name}_${cardSponsor.id}`}>
      <CardContent onClick={toggleContent} className={styles.sponsor_header}>
        <Box className={styles.header_label}>
          {cardSponsor.name}
          {dynamicChevron()}
        </Box>
        <Divider variant="middle" className={expanded ? styles.divider : styles.unflagged} />
      </CardContent>
      <Collapse in={expanded} timeout="auto">
        <CardContent sx={{ display: "flex" }}>
          {renderAppReports()}
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Button
              sx={{ alignSelf: "flex-end", color: "#00A4B3", fontWeight: "600", minWidth: 'unset' }}
              variant="text"
              disabled={editState === "empty"}
              onClick={handleEditControlClick}
            >
              {editControlText}{editIcon()}
            </Button>
            <Box sx={{ width: "10vw", display: "flex" }}>
              <Typography className={styles.error_message}>
                {errorMessage}
              </Typography>
              <WarningIcon
                className={
                  errorMessage ? styles.error_message : styles.unflagged
                }
              />
            </Box>
          </Box>
        </CardContent>
      </Collapse>
    </Card>
  );
}