import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { saveReportPackageElections, sponsorAppsRequest, sponsorsRequest } from './reportManagementApi';
import { MgmtMap, Sponsor, SponsorCard, ElectionsDTO, ReportPackageDTO, SponsorApplicationDTO } from 'common/Types/ReportMgmtTypes';
import { AsyncStatus } from 'common/Types/StateReferenceTypes';
import { RootState } from 'state/store';
import { UserState } from 'redux-oidc';
import { GatewayState } from 'gatewaySlice';
import configData from 'config.json';
import { appTelemetry } from 'services/TelemetryService';
import { apiReqEvnt, apiReqMsg, apiResEvnt, apiResMsg } from 'common/Utils/telemetryUtil';

export interface ReportManagementState {
  status: AsyncStatus;
  sponsorCards: MgmtMap<SponsorCard>;
}
const initialState: ReportManagementState = {
  status: 'idle',
  sponsorCards: {},
}

export const fetchSponsorApps = createAsyncThunk(
  'reportManagement/sponsorAppRequest',
  async (sponsor: Sponsor, { getState }) => {
    const { oidc, gateway } = getState() as { oidc: UserState, gateway: GatewayState };
    const url = `${configData.ENDPOINT_ALX_APIBASEURL}/report-admin/application/${sponsor.id}`;
    appTelemetry(apiReqEvnt, apiReqMsg(url), oidc, gateway, {
      httpRouteEndpoint: url
    });
    
    const response = await sponsorAppsRequest(sponsor).then((res) => {
      appTelemetry(apiResEvnt, apiResMsg(url), oidc, gateway, {
        httpRouteEndpoint: url
      });

      return res;
    });

    return response;
  }
)

export const fetchSponsors = createAsyncThunk(
  'reportManagement/sponsorsRequest',
  async (_, { getState }) => {
    const { oidc, gateway } = getState() as { oidc: UserState, gateway: GatewayState };
    const url = `${configData.ENDPOINT_ALX_APIBASEURL}/report-admin/sponsor`;
    appTelemetry(apiReqEvnt, apiReqMsg(url), oidc, gateway, {
      httpRouteEndpoint: url
    });

    const response = await sponsorsRequest().then((res) => {
      appTelemetry(apiResEvnt, apiResMsg(url), oidc, gateway, {
        httpRouteEndpoint: url
      });
      return res;
    });

    return response;
  }
)

export const saveElections = createAsyncThunk(
  'reportManagement/saveReportPackageElections',
  async (sponsorId: number, { getState }) => {
    const { reportManagement, oidc, gateway } = getState() as { reportManagement: ReportManagementState, oidc: UserState, gateway: GatewayState };
    const dto: ElectionsDTO = {
      sponsorId,
      electionList: Object.values(reportManagement.sponsorCards[sponsorId].pendingReportPackageElections)
    }

    const url = `${configData.ENDPOINT_ALX_APIBASEURL}/report-admin/application/${dto.sponsorId}`;

    appTelemetry(apiReqEvnt, apiReqMsg(url), oidc, gateway, {
      httpRouteEndpoint: url
    });

    const response = await saveReportPackageElections(dto).then((res) => {
      appTelemetry(apiResEvnt, apiResMsg(url), oidc, gateway, {
        httpRouteEndpoint: url
      });
      return res;
    });
    
    return response;
  }
)

export const reportManagementSlice = createSlice({
  name: 'reportManagement',
  initialState,
  reducers: {
    electReportPackageByApp(state: ReportManagementState, action) {
      const dto: ReportPackageDTO = action.payload;
      state.sponsorCards[dto.sponsorId].cardApps[dto.sponsorAppId].reports[dto.reportPackageId].isActive = dto.election;
      // target (contains reports in Map) => clone (contains reports in array). Meets required JSON shape of endpoint.
      const rest = state.sponsorCards[dto.sponsorId].cardApps[dto.sponsorAppId];
      const clone: SponsorApplicationDTO = {
        ...rest,
        reports: Object.values(rest.reports)
      }
      state.sponsorCards[dto.sponsorId].pendingReportPackageElections[dto.sponsorAppId] = clone;
    },
    setSelectedAppRows(state: ReportManagementState, action) {
      const whitelist = action.payload.rows;
      const target = state.sponsorCards[action.payload.sponsorId];
      target.selectedAppRows = whitelist;
      const dynamicdata: any[]=[];
      //similar to lodash pickBy in vanilla js. Returns object with only the whitelisted properties
      target.pendingReportPackageElections = Object.fromEntries(
        Object.entries(target.pendingReportPackageElections)
          .filter(([key]) => whitelist.includes(key))
        )
      
      //reset the report package elections of studies not included in the whitelist
      target.cardApps = Object.fromEntries(
        Object.entries(target.cardApps)
          .map(([key, value]) => {
            if (whitelist.includes(key)) {
              Object.keys(value.reports).forEach((key) => value.reports[key].isActive = false);
              const clone: SponsorApplicationDTO = {
                ...value,
                reports: Object.values(value.reports)
              }
              dynamicdata.push(clone);
            }
            Object.assign(target.pendingReportPackageElections, dynamicdata);
            return [key, value];

          })
      )
    },
    electAllByReport(state: ReportManagementState, action) {
      const dto: ReportPackageDTO = action.payload;
      const target = state.sponsorCards[action.payload.sponsorId];
      const dynamicdata: any[]=[];
      target.cardApps = Object.fromEntries(
        Object.entries(target.cardApps)
          .map(([key, value]) => {
            if (target.selectedAppRows.includes(key)) {
              let report = value.reports[action.payload.reportId];
              report.isActive = action.payload.election;
              const clone: SponsorApplicationDTO = {
                ...value,
                reports: Object.values(value.reports)
              }
              dynamicdata.push(clone);
            }
            return [key, value];
          })
      )
      Object.assign(target.pendingReportPackageElections, dynamicdata);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSponsorApps.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSponsorApps.fulfilled, (state, action) => {
        state.status = 'idle';
        state.sponsorCards[action.payload.cardSponsor.id] = action.payload;
      })
      .addCase(fetchSponsorApps.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchSponsors.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSponsors.fulfilled, (state, action) => {
        state.status = 'idle';
        state.sponsorCards = action.payload;
      })
      .addCase(fetchSponsors.rejected, (state) => {
        state.status = 'failed';
      })
  }
});

export const { electReportPackageByApp, setSelectedAppRows, electAllByReport } = reportManagementSlice.actions;

export const selectSponsorCards = (state: RootState) => state.reportManagement.sponsorCards;
export const selectAsyncStatus = (state: RootState) => state.reportManagement.status;

export default reportManagementSlice.reducer;